import { Injectable } from "@angular/core";
import {
  PropertyCondition,
  PropertyFeature,
  PropertyFloor,
  PropertyHeatingDevice,
  PropertyHeatingType,
  PropertyProjectType,
  PropertyStatus,
  PropertyType,
  PropertyWarmWaterDevice,
  PropertyWaterWaste,
} from "../models/property.models";
import { Subscription } from "../models/payment.models";

@Injectable({
  providedIn: "root",
})
export class TranslationService {
  // The current language
  public language: "en" | "fr" | "lb" = "lb";

  public translatePropertyStatus(status: PropertyStatus): string {
    const dictionary: { [key: string]: string } = {
      "For Sale": $localize`For Sale`,
      "Under Compromise": $localize`Under Compromise`,
      Sold: $localize`Sold`,
      Disabled: $localize`Disabled`,
      "For Rent": $localize`For Rent`,
      Rented: $localize`Rented`,
      Draft: $localize`Draft`,
    };

    if (Object.keys(dictionary).includes(status?.name)) {
      return dictionary[status.name];
    } else {
      console.warn("Unknown property status in translation", status?.name);
      return status?.name;
    }
  }

  public translatePropertyProjectType(project_type: PropertyProjectType): string {
    const dictionary: { [key: string]: string } = {
      Building: $localize`Building`,
      Residence: $localize`Residence`,
      Subdivision: $localize`Subdivision`,
      Hamlet: $localize`Hamlet`,
      Domain: $localize`Domain`,
      Property: $localize`Property`,
      Street: $localize`Street`,
      Hotel: $localize`Hotel`,
    };

    if (Object.keys(dictionary).includes(project_type?.name)) {
      return dictionary[project_type.name];
    } else {
      console.warn("Unknown property project type in translation", project_type?.name);
      return project_type?.name;
    }
  }

  public translatePropertyHeatingDevice(device: PropertyHeatingDevice): string {
    const dictionary: { [key: string]: string } = {
      Convector: $localize`Convector`,
      "On the ground": $localize`On the ground`,
      Radiator: $localize`Radiator`,
      Stove: $localize`Stove`,
      "Air conditioning": $localize`Air conditioning`,
      Central: $localize`Central`,
      "No heating": $localize`No heating`,
      Fireplace: $localize`Fireplace`,
    };
    if (Object.keys(dictionary).includes(device?.name)) {
      return dictionary[device.name];
    } else {
      console.warn("Unknown property heating device in translation", device?.name);
      return device?.name;
    }
  }

  public translatePropertyWaterWaste(waste: PropertyWaterWaste): string {
    const dictionary: { [key: string]: string } = {
      "Septic tank": $localize`Septic tank`,
      Drainage: $localize`Drainage`,
      "Wastewater treatment plant": $localize`Wastewater treatment plant`,
      "All-water pit": $localize`All-water pit`,
      "Micro sewage treatment plant": $localize`Micro sewage treatment plant`,
    };
    if (Object.keys(dictionary).includes(waste?.name)) {
      return dictionary[waste.name];
    } else {
      console.warn("Unknown property waste waster in translation", waste?.name);
      return waste?.name;
    }
  }

  public translatePropertyFloor(floor: PropertyFloor): string {
    const dictionary: { [key: string]: string } = {
      "Ground floor": $localize`Ground floor`,
      "Last floor": $localize`Last floor`,
      Basement: $localize`Basement`,
      "Single storey": $localize`Single storey`,
      Elevated: $localize`Elevated`,
    };
    if (Object.keys(dictionary).includes(floor?.name)) {
      return dictionary[floor.name];
    } else {
      // In case the floor is not a number and we have not a translation then show the warning
      if (isNaN(+floor?.name)) {
        console.warn("Unknown property floor in translation", floor?.name);
      }
      return floor?.name;
    }
  }

  public translatePropertyHeatingType(type: PropertyHeatingType): string {
    const dictionary: { [key: string]: string } = {
      Gas: $localize`Gas`,
      "Fuel Oil": $localize`Fuel Oil`,
      Electric: $localize`Electric`,
      Wood: $localize`Wood`,
      Solar: $localize`Solar`,
      Coal: $localize`Coal`,
      "Heat pump": $localize`Heat pump`,
      Geothermal: $localize`Geothermal`,
      "Wood Pellets/Pellets": $localize`Wood Pellets/Pellets`,
      "Hot water": $localize`Hot water`,
      Aerothermy: $localize`Aerothermy`,
      "Wood chips": $localize`Wood chips`,
    };
    if (Object.keys(dictionary).includes(type?.name)) {
      return dictionary[type.name];
    } else {
      console.warn("Unknown property heating type in translation", type?.name);
      return type?.name;
    }
  }

  public translatePropertyWarmWaterDevice(device: PropertyWarmWaterDevice): string {
    const dictionary: { [key: string]: string } = {
      Solar: $localize`Solar`,
      "Water heater": $localize`Water heater`,
      Boiler: $localize`Boiler`,
      "Heat pump": $localize`Heat pump`,
    };
    if (Object.keys(dictionary).includes(device?.name)) {
      return dictionary[device.name];
    } else {
      console.warn("Unknown property warm water device in translation", device?.name);
      return device?.name;
    }
  }

  public translatePropertyCondition(condition: PropertyCondition): string {
    const dictionary: { [key: string]: string } = {
      Poor: $localize`Poor`,
      Normal: $localize`Normal`,
      Good: $localize`Good`,
      Excellent: $localize`Excellent`,
      New: $localize`New`,
    };
    if (Object.keys(dictionary).includes(condition?.name)) {
      return dictionary[condition.name];
    } else {
      console.warn("Unknown property condition in translation", condition?.name);
      return condition?.name;
    }
  }

  public translatePropertyFeature(feature: PropertyFeature): string {
    const dictionary: { [key: string]: string } = {
      Attic: $localize`Attic`,
      "Parking space": $localize`Parking space`,
      "Rainwater harvesting": $localize`Rainwater harvesting`,
      "Seperate Toilet": $localize`Seperate Toilet`,
      "Balcony/Terrace": $localize`Balcony/Terrace`,
      Balcony: $localize`Balcony`,
      Cellar: $localize`Cellar`,
      Internet: $localize`Internet`,
      Chimney: $localize`Chimney`,
      "Reduced mobility access": $localize`Reduced mobility access`,
      "Air conditioning": $localize`Air conditioning`,
      "Security System": $localize`Security System`,
      Elevator: $localize`Elevator`,
      Pool: $localize`Pool`,
      "Reinforced door": $localize`Reinforced door`,
      "Roller shutters": $localize`Roller shutters`,
      "Electric roller shutters": $localize`Electric roller shutters`,
      Jacuzzi: $localize`Jacuzzi`,
      Sauna: $localize`Sauna`,
      Dishwasher: $localize`Dishwasher`,
      "Smart Home": $localize`Smart Home`,
      Furniture: $localize`Furniture`,
      "Clothes Dryer": $localize`Clothes Dryer`,
      "Washing Machine": $localize`Washing Machine`,
      Refrigerator: $localize`Refrigerator`,
      Freezer: $localize`Freezer`,
      "Laundry Room": $localize`Laundry Room`,
      "Animals accepted": $localize`Animals accepted`,
      Garden: $localize`Garden`,
      "Solar panels": $localize`Solar panels`,
      Cinema: $localize`Cinema`,
      Wellness: $localize`Wellness`,
    };

    if (Object.keys(dictionary).includes(feature?.name)) {
      return dictionary[feature.name];
    } else {
      console.warn("Unknown property feature in translation", feature?.name);
      return feature?.name;
    }
  }

  public translatePropertyType(type: PropertyType): string {
    const dictionary: { [key: string]: string } = {
      House: $localize`House`,
      Apartment: $localize`Apartment`,
      Room: $localize`Room`,
      Building: $localize`Building`,
      "Garage/Parking": $localize`Garage/Parking`,
      Ground: $localize`Ground`,
      Other: $localize`Other`,
      Office: $localize`Office`,
      Business: $localize`Business`,
    };
    if (Object.keys(dictionary).includes(type?.name)) {
      return dictionary[type.name];
    } else {
      console.warn("Unknown property type in translation", type?.name);
      return type?.name;
    }
  }

  public translateSubscription(subscription: Subscription): string {
    const dictionary: { [key: string]: string } = {
      Free: $localize`Free`,
      Basic: $localize`Basic`,
      Professional: $localize`Professional`,
      Inherited: $localize`Inherited`,
    };

    if (Object.keys(dictionary).includes(subscription?.name)) {
      return dictionary[subscription.name];
    } else {
      console.warn("Unknown subscription in translation", subscription?.name);
      return subscription?.name;
    }
  }

  public translateSubscriptionDesc(subscription: Subscription): string {
    const dictionary: { [key: string]: string } = {
      "The first property is free": $localize`The first property is free`,
      "Our most advanced and complete package": $localize`Our most advanced and complete package`,
      "Your account is managed by an administrator": $localize`Your account is managed by an administrator`,
    };

    if (Object.keys(dictionary).includes(subscription?.description)) {
      return dictionary[subscription.description];
    } else {
      console.warn("Unknown subscription description in translation", subscription?.description);
      return subscription?.description;
    }
  }

  public translatePoiType(poiType: string) {
    const dictionary: { [key: string]: string } = {
      daycare: $localize`Daycare`,
      school: $localize`School`,
      highschool: $localize`Highschool`,
      pharmacy: $localize`Pharmacy`,
    };
    if (Object.keys(dictionary).includes(poiType)) {
      return dictionary[poiType];
    } else {
      console.warn("Unknown POI type in translation", poiType);
      return poiType;
    }
  }
}

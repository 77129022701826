import { Inject, Injectable, InjectionToken, PLATFORM_ID } from "@angular/core";
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from "@angular/common";
import { Meta } from "@angular/platform-browser";
import { TranslationService } from "./translation.service";

@Injectable({
  providedIn: "root",
})
export class SsrService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>,
    @Inject(DOCUMENT) private document: Document,
    private translationService: TranslationService,
    private meta: Meta
  ) {}

  /**
   * Updates meta tags for the given URL, title, description, and image.
   *
   * @param {string} url - The URL to update meta tag.
   * @param {string} title - The title to update meta tag.
   * @param {string} description - The description to update meta tag.
   * @param {string} image - The image to update meta tag.
   */
  public updateMetaTags(url: string, title: string, description: string, image: string) {
    // We add the first image as meta tag for the corresponding entities
    if (url) {
      this.meta.updateTag({ property: "og:url", content: url });
    }
    if (title) {
      if (title.length > 63) {
        title = title.slice(0, 63);
      }
      this.meta.updateTag({ property: "og:title", content: title });
    }
    if (image) {
      this.meta.updateTag({ property: "og:image", content: image });
    }
    if (description) {
      if (description.length > 300) {
        description = description.slice(0, 300);
      }
      this.meta.updateTag({ name: "description", content: description });
      this.meta.updateTag({ property: "og:description", content: description });
    }
  }

  public resetMetaTags() {
    const description = $localize`Homie is a new real estate portal dedicated to Luxembourg. Our mission is to help all stakeholders navigate the real estate market successfully and with confidence.`;
    this.meta.updateTag({
      property: "og:url",
      content: `https://www.homie.lu/${this.translationService.language}`,
    });
    this.meta.updateTag({ property: "og:title", content: "Homie" });
    this.meta.updateTag({
      property: "og:image",
      content: "https://www.homie.lu/assets/logos/logo_primary_large.png",
    });
    this.meta.updateTag({ property: "og:locale", content: this.translationService.language });
    this.meta.updateTag({ property: "og:description", content: description });
    this.meta.updateTag({ name: "description", content: description });
  }

  public setCanonicalUrl(canonical: string) {
    const links = this.document.head.getElementsByTagName("link");
    for (const link in links) {
      let element = links[link];
      // Default canonical url is french
      if (element.rel === "canonical") {
        element.href = `https://www.homie.lu/fr${canonical}`;
      } else if (element.rel === "alternate") {
        if (element.hreflang === "en") {
          element.href = `https://www.homie.lu/en${canonical}`;
        } else if (element.hreflang === "fr") {
          element.href = `https://www.homie.lu/fr${canonical}`;
        } else if (element.hreflang === "lb") {
          element.href = `https://www.homie.lu/lb${canonical}`;
        } else {
          console.warn("Unknown hreflang in link of head section. Could not be parsed.");
        }
      }
    }
  }

  public isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  public isServer(): boolean {
    return isPlatformServer(this.platformId);
  }

  public getWindow(): Window | null {
    if (this.isBrowser()) {
      return window;
    } else {
      return null;
    }
  }

  public getLocation(): Location | null {
    if (this.isBrowser()) {
      return location;
    } else {
      return null;
    }
  }

  public setLocalStorage(key: string, value: string): void {
    if (this.isBrowser()) {
      localStorage.setItem(key, value);
    }
  }

  public deleteLocalStorage(key: string): void {
    if (this.isBrowser()) {
      localStorage.removeItem(key);
    }
  }

  public getLocalStorage(key: string): string | null {
    if (this.isBrowser()) {
      return localStorage.getItem(key);
    } else {
      return null;
    }
  }
}

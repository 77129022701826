import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { SsrService } from "./ssr-service";
import { MortgageClientForm } from "../models/settings.models";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ConsultingClientForm } from "../models/consulting.modals";

@Injectable({
  providedIn: "root",
})
export class DataService {
  public backendUrl = `${environment.BACKEND_URL}`;
  public assetsUrl = `${environment.ASSETS_URL}`;
  public version: string = "unknown";
  public showFooter: boolean = true;

  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  constructor(
    private ssrService: SsrService,
    private http: HttpClient
  ) {}

  // Adds a thousand seperator to a number. the seperator is a simply space
  public numberSeperator(x: number) {
    let xString = x.toString();
    const pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(xString)) xString = xString.replace(pattern, "$1.$2");
    return xString;
  }

  // This function returns the corresponding heatmap color based on a percentage value
  // 100 is red and 0 is green
  public getHeatMapColor(value: number) {
    const hue = (1 - value / 100) * 120;
    return ["hsl(", hue.toString(10), ",80%,45%)"].join("");
  }

  public getBaseHref(): string {
    const location = this.ssrService.getLocation();
    if (location?.pathname.startsWith("/en")) {
      return "/en";
    } else if (location?.pathname.startsWith("/fr")) {
      return "/fr";
    } else if (location?.pathname.startsWith("/lb")) {
      return "/lb";
    } else {
      return "";
    }
  }

  public sendMortgageClient(client: MortgageClientForm) {
    return this.http.post(
      `${this.backendUrl}/mortgagelu/client`,
      client,
      this.httpOptions
    );
  }

  public sendConsultingClient(client: ConsultingClientForm) {
    return this.http.post(
      `${this.backendUrl}/cosulting/client`,
      client,
      this.httpOptions
    );
  }
}
